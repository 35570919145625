import { Row, Col, Button, Menu, Dropdown, Skeleton, Space } from "antd";
import { OptionProps } from "antd/lib/select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { ActivityStatusEnum, ActivityTypeEnum } from "../../../../enums/activity.enum";
import { InvoiceEnum } from "../../../../enums/invoice.enum";
import { UserTypes } from "../../../../enums/userTypes.enum";
import { Activity } from "../../../../models/activity.model";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import ContainerService from "../../../../services/ContainerService/container.service";
import InvoiceService from "../../../../services/InvoiceService/invoice.service";
import MetaService from "../../../../services/MetaService/meta.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import ContainersContainer from "../../../../store/container/ContainersContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { ContainerReducerProps } from "../../../../store/reducers/containerReducer";
import { convertToTitleCase } from "../../../utils/convertToTitleCase";
import ButtonComponent from "../../ButtonComponent";
import CreateInvoiceButton from "../../CreateInvoiceButton";
import TableComponent from "../../TableComponent";
import UIModal from "../../UIModal";
import ContainerActivityTimeline from "../ContainerActivityTimeline";
import "./containerFormBodyActions.scss"

const { Item } = Menu
interface ContainerFormBodyActionsProps extends ContainerReducerProps, AuthReducerProps {
    loading: boolean
    disableUpdate: boolean
    addItemClickHandler: () => void
    refreshListHandler: (options: {
        container?: boolean,
        activities?: boolean,
        activityItems?: number,
    }) => void
}



const invoiceHistoryColumns = [
    {
        title: "Invoice No.",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
    },
    {
        title: "Invoice Date",
        dataIndex: "date",
        key: "date",
        render: (text: string) => <span>{text ? moment(text).format("DD MMM, YYYY") : ""}</span>,
    },
    {
        title: "Total Cost",
        dataIndex: "totalCost",
        key: "totalCost",
    },
    {
        title: "Status",
        dataIndex: "historyStatus",
        key: "historyStatus",
        render: (text: string) => <p className={`status ${text}`}>{text?.replaceAll('_', ' ')}</p>,
    },
]

const ContainerFormBodyActions = (props: ContainerFormBodyActionsProps) => {
    const {
        loading,
        activity,
        setActivity,
        addItemClickHandler,
        refreshListHandler,
        disableUpdate,
        user,
        container,
    } = props

    const history = useHistory()

    const {
        submitting,
        exportSubmitting,
        updateContainerActivity,
        exportContainerActivity,
        exportCustomerPendingActivity,
    } = ContainerService()

    const {
        mailInvoice,
    } = InvoiceService()

    const {
        activityStatuses: rawStatuses,
        fetchActivityStatusMeta,
    } = MetaService()

    const {
        createInvoices,
    } = InvoiceService()

    const [status, setStatus] = useState<{ label: string, value: ActivityStatusEnum }>()

    const [activityStatuses, setActivityStatuses] = useState<{ label: string, value: string }[]>([])

    const [invoiceHistoryVisible, setInvoiceHistoryVisible] = useState<boolean>(false)

    useEffect(() => {
        if (activity.id)
            fetchActivityStatusMeta(activity.id)
    }, [])

    useEffect(() => {
        if (user?.role?.toLowerCase() === UserTypes.ADMIN)
            setActivityStatuses(rawStatuses.map(sts => ({ label: sts, value: sts })))
        else
            setActivityStatuses(rawStatuses.map(sts => {
                if (sts === ActivityStatusEnum.QUOTE_DRAFT)
                    return { value: sts, label: 'request_revision' }
                if (sts === ActivityStatusEnum.READY_FOR_REPAIR)
                    return { value: sts, label: 'approve' }
                return { value: sts, label: sts }
            }))
    }, [rawStatuses])

    useEffect(() => {
        setStatus(prev => {
            const status = activityStatuses?.find(sts => activity.activityStatus === sts.value)
            return status || prev as any
        })
    }, [activityStatuses])

    useEffect(() => {
        setStatus({ label: activity?.activityStatus || '', value: activity.activityStatus || '' as ActivityStatusEnum })
    }, [activity])

    // function to remove spaces and dashes from Container Number
    const containerNumberFormatting = (value: string) => {
        let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
        return formattedValue.toUpperCase()
    }
    //currently code has error so that first if will always be true and anytime export button is shown it will exportCustomerPendingActivity.  If fix is dersired change to:  
    //if(activity?.activityStatus===ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL ||  activity?.activityStatus === ActivityStatusEnum.READY_FOR_BILLING)
    const exportHandler = (exportOption: "with_image" | "without_image") => {
        if (!activity?.id || !user?.role) return
        if (ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL || ActivityStatusEnum.READY_FOR_BILLING) {
            exportCustomerPendingActivity([activity?.id], user?.role?.toLowerCase(), exportOption, `${containerNumberFormatting(String(container.containerNumber))}-${activity.activityUid}`)
        } else
            exportContainerActivity(activity?.id, exportOption)
    }

    const updateHandler = async () => {
        if (!user?.role) return
        const updatedActivity = {
            ...activity,
            activityStatus: status?.value
        }
        try {
            const activity = await updateContainerActivity(updatedActivity, user?.role?.toLowerCase())
            if (activity)
                setActivity(activity)
            refreshListHandler({
                container: true,
                activities: true,
            })
        } catch (error) {

        }
    }
    const openInvoiceHistoryModal = () => setInvoiceHistoryVisible(true)

    const closeInvoiceHistoryModal = () => setInvoiceHistoryVisible(false)

    const mailInvoiceHanlder = () => {
        activity.invoiceIds?.length && mailInvoice(activity.invoiceIds[0])
    }

    const viewInvoiceMenu = <Menu>
        <Item onClick={openInvoiceHistoryModal}>View Invoice History</Item>
        <Item onClick={mailInvoiceHanlder}>Send Invoice</Item>
        {/* <Item onClick={exportHandler}>Export Invoice</Item> */}
    </Menu>

    const handleRedirectionToInvoiceDetail = () => {
        if (activity.invoiceIds?.length)
            history.push(generatePath(AppRoutes.INVOICE, { invoiceId: activity.invoiceIds[0] }))
    }

    const createInvoiceHandler = async (mode: InvoiceEnum) => {
        if (!activity.id) return
        const response = await createInvoices({ activity_ids: [activity.id] }, mode)
        if (response)
            refreshListHandler({
                container: true,
                activities: true,
            })
    }

    const loader = <Space className="container-form-body-actions__loader">
        <Skeleton.Button active size="large" shape="round" />
        <Skeleton.Button active size="large" shape="round" />
    </Space>

    const Export = !disableUpdate && <CreateInvoiceButton
        buttonType="default"
        iconClass="icon-export"
        primaryBtnText={`Export${activity.activityStatus === ActivityStatusEnum.BILLED && activity?.activityType ? ` ${convertToTitleCase(activity?.activityType)} Form` : ""}`}
        primaryBtnLoading={exportSubmitting}
        primaryBtnOnClick={() => exportHandler("without_image")}
        dropdownButtons={[
            {
                text: "Export with images",
                onClick: () => exportHandler("with_image")
            }
        ]} />

    const generateLeftColumnActionsBasedOnStatus = () => {
        if (!activity?.activityStatus) return loader

        const Timeline = !!activity?.activityTimeline?.length && <ContainerActivityTimeline
            loading={loading}
            timeline={activity?.activityTimeline}
            isRepairForm={activity?.activityType === ActivityTypeEnum.REPAIR}
        />

        const ViewInvoice = <CreateInvoiceButton
            primaryBtnLoading={false}
            primaryBtnText="View Invoice"
            primaryBtnOnClick={() => { }}
            buttonType="default"
            dropdownButtons={[
                {
                    text: "View invoice history",
                    onClick: () => { }
                }
            ]}
        />

        const Invoice = <div className="double-btn__container">
            <ButtonComponent
                suffix={
                    <Dropdown
                        trigger={["click"]}
                        overlay={viewInvoiceMenu}
                        overlayClassName="activity-dropdown default"
                        placement="bottomRight"
                    >
                        <ButtonComponent
                            type="default"
                            iconClass="icon-dropdown"
                            className="suffix-btn"
                        />
                    </Dropdown>
                }
                type="default"
                iconClass="icon-invoice"
                text="View Invoice"
                onClick={handleRedirectionToInvoiceDetail}
            />
        </div>

        const InvoiceHistory = <ButtonComponent
            iconClass="icon-invoice"
            text="View Invoice History"
            onClick={openInvoiceHistoryModal}
        />

        switch (activity?.activityStatus) {
            case ActivityStatusEnum.QUOTE_DRAFT:
            case ActivityStatusEnum.QUOTE_ISSUED:
                return [Timeline]
            case ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL:
            case ActivityStatusEnum.READY_FOR_BILLING:
            // return [Timeline, Export]
            case ActivityStatusEnum.READY_FOR_REPAIR:
            case ActivityStatusEnum.REPAIR_DRAFT:
            case ActivityStatusEnum.REPAIR_DONE:
            case ActivityStatusEnum.REPAIR_PENDING_ADMIN_APPROVAL:
            case ActivityStatusEnum.PENDING_ADMIN_APPROVAL:
                return [Timeline, Export]
            case ActivityStatusEnum.BILLED:
                return [Timeline, Invoice]
            // case ActivityStatusEnum.READY_FOR_BILLING:
            case ActivityStatusEnum.VOID:
                return [Timeline, InvoiceHistory]
            default:
                return []
        }
    }

    const statusDropDownGenerator = () => {
        return (
            <Menu className="activity-status-menu" mode="vertical">
                {activityStatuses?.map((status) => <Menu.Item
                    className="status-item" key={status.value} onClick={() => setStatus(status as any)}>
                    {status.label.replaceAll("_", " ")}
                </Menu.Item>)}
            </Menu>
        )
    }

    const generateRightColumnActionsBasedOnStatus = () => {
        if (!activity?.activityStatus && user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()) return loader

        const AddItem = user?.role?.toLowerCase() === UserTypes.ADMIN?.toLowerCase() && <ButtonComponent
            className="secondary-button"
            text="Add Item"
            onClick={addItemClickHandler}
        />
        const StatusDropdown = !disableUpdate && (user?.role?.toLowerCase() === UserTypes.ADMIN?.toLowerCase() || activity?.activityStatus === ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL) && <div className="status-dropdown__container">
            <Dropdown
                overlay={statusDropDownGenerator}
                trigger={['click']}>
                <ButtonComponent
                    text={status?.label?.replaceAll("_", " ")}
                />
            </Dropdown>
        </div>
        const Update = !disableUpdate && (user?.role?.toLowerCase() === UserTypes.ADMIN?.toLowerCase() || activity?.activityStatus === ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL) && <ButtonComponent
            type="primary"
            text="Update"
            disabled={status === activity?.activityStatus}
            loading={submitting}
            onClick={updateHandler}
        />
        const CreateInvoice = user?.role?.toLowerCase() === UserTypes.ADMIN?.toLowerCase() && <CreateInvoiceButton
            primaryBtnLoading={false}
            primaryBtnText="Create Invoice"
            primaryBtnOnClick={() => createInvoiceHandler(InvoiceEnum.WITHOUT_IMAGES)}
            dropdownButtons={[
                {
                    text: "Create invoice with images",
                    onClick: () => createInvoiceHandler(InvoiceEnum.WITH_IMAGES)
                }
            ]}
        />

        switch (activity?.activityStatus) {
            case ActivityStatusEnum.QUOTE_DRAFT:
            case ActivityStatusEnum.QUOTE_ISSUED:
            case ActivityStatusEnum.PENDING_ADMIN_APPROVAL:
            case ActivityStatusEnum.REPAIR_DRAFT:
                return [AddItem, StatusDropdown, Update]
            case ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL:
            case ActivityStatusEnum.READY_FOR_REPAIR:
            case ActivityStatusEnum.REPAIR_DONE:
            case ActivityStatusEnum.REPAIR_PENDING_ADMIN_APPROVAL:
                return [StatusDropdown, Update]
            case ActivityStatusEnum.READY_FOR_BILLING:
                return [StatusDropdown, Update, CreateInvoice]
            case ActivityStatusEnum.BILLED:
                return [Export]
            case ActivityStatusEnum.VOID:
                return [CreateInvoice, AddItem]
            default:
                return []
        }
    }

    return <Row className="container-form-body-actions__container">
        <Col>
            {generateLeftColumnActionsBasedOnStatus()}
        </Col>
        <Col>
            {generateRightColumnActionsBasedOnStatus()}
        </Col>
        <UIModal
            className="invoice-history-modal"
            visible={invoiceHistoryVisible}
            onCancel={closeInvoiceHistoryModal}
            title={`${activity.activityUid} - ${moment(activity.activityDate).format('DD MMM YYYY')}`}
        >
            <p>Invoice History</p>
            <TableComponent pagination={false} columns={invoiceHistoryColumns} data={activity.invoiceTimeline || []} loading={false} />
        </UIModal>
    </Row>
}

export default ContainersContainer(AuthContainer(ContainerFormBodyActions))