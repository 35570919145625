import { ContainerLog } from './../../models/containerLog.model';
import { generatePath } from 'react-router-dom';
import { UserTypes } from './../../enums/userTypes.enum';
import { ActivityTypeEnum } from './../../enums/activity.enum';
import { ActivityStatus } from './../../shared/definitions/activity.definition';
import { ContainerNavigation } from './../../models/container.model';
import { Activity, ActivityItems } from './../../models/activity.model';
import { serialize, deserialize } from 'serializr';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { useState } from 'react';
import { Container } from "../../models/container.model"
import axiosInstance from '../../interceptor/axiosInstance';
import { convertJSONToFormData } from '../../shared/utils/dataFormatConverter';
import { ActivityEnum, ActivityStatusEnum } from '../../enums/activity.enum';
import { fileDownloader, fileExtensionGenerator } from '../../shared/utils/fileDownloader';
import { Pagination } from '../../models/pagination.model';
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ContainerComment } from '../../models/containerComment.model';

const ContainerService = () => {

    const [submitting, setSubmitting] = useState<boolean>(false)

    const [exportSubmitting, setExportSubmitting] = useState<boolean>(false)

    const [exportImagesSubmitting, setExportImagesSubmitting] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)

    const [activityLoading, setActivityLoading] = useState<boolean>(false)

    const [containerActivitiesLoading, setContainerActivitiesLoading] = useState<boolean>(false)

    const [container, setContainer] = useState<Container>()

    const [containerActivities, setContainerActivities] = useState<Activity[]>([])

    const [containerNavigation, setContainerNavigation] = useState<ContainerNavigation>()

    const [activities, setActivities] = useState<Activity[]>([])

    const [activityItems, setActivityItems] = useState<ActivityItems[]>([])

    const [containerComments, setContainerComments] = useState<ContainerComment[]>([])

    const [containerLogs, setContainerLogs] = useState<ContainerLog[]>([])

    const [activity, setActivity] = useState<Activity>()

    const [activitiesMeta, setActivitiesMeta] = useState<Pagination>({})

    const fetchSingleContainer = async (containerId: string, userType: UserTypes | string) => {
        setLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_SPECIFIC, { containerId, userType })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(Container, response.data['container']) as Container
            const navigation = deserialize(ContainerNavigation, response.data['navigation'])
            setContainer(data)
            setContainerNavigation(navigation)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchActivites = async (params: {
        status: ActivityEnum,
        container_id?: number,
        search_text?: string,
        page?: number,
        yard_id?: string,
    }, userType: UserTypes) => {
        setActivities([])
        setLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES, { userType })
            const response = await axiosInstance.get(API_URL, { params })
            const data = deserialize(Activity, response.data['activities']) as Activity[]
            const meta = deserialize(Pagination, response.data['meta'])
            setActivities(data)
            setActivitiesMeta(meta)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchSingleContainerActivites = async (params: {
        container_id?: number,
    }, userType: UserTypes | string) => {
        setContainerActivitiesLoading(true)
        setContainerActivities([])
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITIES, { userType })
            const response = await axiosInstance.get(API_URL, { params })
            const data = deserialize(Activity, response.data['activities']) as Activity[]
            setContainerActivities(data)
        } catch (error) {

        } finally {
            setContainerActivitiesLoading(false)
        }
    }

    const createContainer = async (container: Container, userType: UserTypes) => {
        setSubmitting(true)
        const payload = { container: serialize(Container, container) }
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS, { userType })
            const response = await axiosInstance.post(API_URL, payload)
            Notification({
                type: NotificationTypes.SUCCESS,
                message: "",
                description: "Container created successfully",
            });
            return response.data
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }

    const updateContainer = async (container: Container, userType: UserTypes) => {
        setSubmitting(true)
        const payload = { container: serialize(Container, container) }
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_SPECIFIC, { userType, containerId: String(container?.id) })
            const response = await axiosInstance.put(API_URL, payload)
            const data = deserialize(Container, response.data['container']) as Container
            setContainer(data)
            return data
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }

    const exportActivites = async (activityId: number[]) => {
        try {
            const API_URL = ApiRoutes.CONTAINER_ACTIVITIES_EXPORT
            const response = await axiosInstance.get(API_URL, { responseType: "blob" })
            fileDownloader(response?.data, `Activities.${fileExtensionGenerator(response.data.type)}`);
        } catch (error) {
        }
    }

    const downloadContainerImages = async (container: Container) => {
        setSubmitting(true)
        try {
            const API_URL = ApiRoutes.CONTAINERS_SPECIFIC_IMAGES_DOWNLOAD.replace(":containerId", String(container?.id))
            const response = await axiosInstance.get(API_URL, {
                responseType: 'blob'
            })
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', container?.containerNumber + '.zip'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const approveActivites = async (activity_status: ActivityStatusEnum, activity_ids: number[]) => {
        setSubmitting(true)
        const payload = {
            activity: {
                activity_status,
                activity_ids,
            }
        }
        const params = {
            activity_status
        }
        try {
            const API_URL = ApiRoutes.CONTAINER_ACTIVITIES_APPROVAL
            const response = await axiosInstance.post(API_URL, payload, { params })
            return response
        } catch (error) {
        } finally {
            setSubmitting(true)
        }
    }

    const createContainerActivity = async (activity: Activity, userType: UserTypes | string) => {
        setSubmitting(true)
        const payload = serialize(Activity, activity)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES, { userType })
            const response = await axiosInstance.post(API_URL, payload)
            if (response)
                Notification({
                    message: "",
                    description: "Activity created successfully",
                    type: NotificationTypes.SUCCESS,
                });
            return response
        } catch (error) {
            console.log(error);

            throw error
        } finally {
            setSubmitting(false)
        }
    }

    const fetchContainerActivityDetails = async (activityId: string, userType: string) => {
        setActivityLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC, { activityId, userType })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(Activity, response.data["activity"])
            setActivity(data);
            return data;

        } catch (error) {

        } finally {
            // setActivityLoading(false)
        }
    }

    const deleteContainerActivity = async (activityId: number, userType: string) => {
        setActivityLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC, { activityId, userType })
            await axiosInstance.delete(API_URL)
            Notification({
                message: "",
                description: "Activity deleted successfully",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
            throw error
        } finally {
            setActivityLoading(false)
        }
    }

    const updateContainerActivity = async (activity: Activity, userType: string) => {
        setSubmitting(true)
        const activityId = activity?.id
        const payload = { activity: serialize(Activity, activity) }
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC, { activityId, userType })
            const response = await axiosInstance.put(API_URL, payload)
            const data = deserialize(Activity, response.data['activity'])
            Notification({
                message: "",
                description: "Activity updated successfully",
                type: NotificationTypes.SUCCESS,
            });
            return data
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const exportContainerActivity = async (activityId: number, export_option: "with_image" | "without_image") => {
        setExportSubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_EXPORT, { activityId })
            const response = await axiosInstance.get(API_URL, { responseType: "blob", params: { export_option } })
            fileDownloader(response.data, `container activities.${fileExtensionGenerator(response.data.type)}`);
            Notification({
                message: "",
                description: "Container activity exported successfully",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
            setExportSubmitting(false)
        }
    }

    const exportCustomerPendingActivity = async (
        activityIds: number[],
        userType: string,
        export_option: "with_image" | "without_image",
        fileName?: string
    ) => {
        setExportSubmitting(true)
        try {
            const payload = { activity_ids: activityIds }
            const API_URL = generatePath(ApiRoutes.INVOICES_EXPORT, { userType })
            const response = await axiosInstance.post(API_URL, payload, { responseType: "blob", params: { export_option } })
            console.log(response.data.type)
            fileDownloader(response.data, (fileName || `container-activities`) + `.${fileExtensionGenerator(response.data.type)}`);
            Notification({
                message: "",
                description: "Container activity exported successfully",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
            setExportSubmitting(false)
        }
    }

    const exportBulkImageActivity = async (
        activityIds: number[],
        userType: string,
        export_option: "with_image" | "without_image",
        fileName?: string
    ) => {
        setExportImagesSubmitting(true)
        try {
            const payload = { activity_ids: activityIds }
            const API_URL = generatePath(ApiRoutes.INVOICE_IMAGE_EXPORT, { userType })
            const response = await axiosInstance.post(API_URL, payload, { responseType: "blob", params: { export_option } })
            console.log(response.data.type)
            fileDownloader(response.data, (fileName || `container-activities`) + `.${fileExtensionGenerator(response.data.type)}`);
            Notification({
                message: "",
                description: "Container activity exported successfully",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
            setExportImagesSubmitting(false)
        }
    }


    const fetchContainerActivityItems = async (activityId: string, userType: UserTypes | string) => {
        setActivityLoading(true)
        const params = { activityId }
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_ITEMS, { activityId, userType })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(ActivityItems, response.data['activity_items']) as ActivityItems[]
            setActivityItems(data)
            return data
        } catch (error) {
        } finally {
            setActivityLoading(false)
        }
    }

    const autoPopulatedActivityItemsForm = async (params: {
        repair_code: string,
        activity_id: number
    }) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_FORM_AUTOPOPULATE)
            const response = await axiosInstance.post(API_URL, params)
            const data = deserialize(ActivityItems, response.data)
            return data
        } catch (error) {

        }
    }

    const createActivityItem = async (activityId: number, item: ActivityItems, userType: UserTypes | string) => {
        setSubmitting(true)
        const payload = { activity_item: serialize(ActivityItems, item) }
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_ITEMS, { activityId, userType })
            const response = await axiosInstance.post(API_URL, payload)
            Notification({
                message: "",
                description: "Activity Item created successfully",
                type: NotificationTypes.SUCCESS,
            });
            return response
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const updateActivityItem = async (activityId: number, item: ActivityItems) => {
        setSubmitting(true)
        const payload = { activity_item: serialize(ActivityItems, item) }
        try {
            const API_URL = generatePath(
                ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_ITEM,
                { activityId, activityItemId: item?.id }
            )
            const response = await axiosInstance.put(API_URL, payload)
            Notification({
                message: "",
                description: "Activity Item updated successfully",
                type: NotificationTypes.SUCCESS,
            });
            return response
        } catch (error) {
            throw error;

        } finally {
            setSubmitting(false)
        }
    }

    const deleteActivityItem = async (activityId: number, activityItemId: number) => {
        setSubmitting(true)
        try {
            const API_URL = generatePath(
                ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_ITEM,
                { activityId, activityItemId }
            )
            const response = await axiosInstance.delete(API_URL)
            Notification({
                message: "",
                description: "Activity Item deleted successfully",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const updateActivityDate = async (payload: {
        activity_date: string,
        activity_id: number
    }) => {
        setSubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_DATE_UPDATE)
            const response = await axiosInstance.post(API_URL, payload)
            const data = deserialize(Activity, response.data['activity'])
            Notification({
                message: "",
                description: "Activity Date updated successfully",
                type: NotificationTypes.SUCCESS,
            });
            return data
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const fetchContainerComments = async (container_id: string | number) => {
        setLoading(true)
        try {
            const params = { container_id }
            const response = await axiosInstance.get(ApiRoutes.CONTAINER_COMMENTS, { params })
            // const faker = [{
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "nis",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "Tamil",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "nis",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "Tamil",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "nis",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "Tamil",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "nis",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "Tamil",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "nis",
            //         id: 1
            //     }
            // }, {
            //     id: 1,
            //     comment: "Good",
            //     createdAt: "21-Nov-2019",
            //     commentedBy: {
            //         firstName: "nishanth",
            //         lastName: "Tamil",
            //         id: 1
            //     }
            // },]
            const data = deserialize(ContainerComment, response.data["comments"]) as ContainerComment[]
            setContainerComments(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const createContainerComments = async (containerId: string | number, comment_text: string) => {
        setSubmitting(true)
        try {
            const payload = {
                comment: { comment_text },
                container_id: containerId,
            }
            const API_URL = generatePath(ApiRoutes.CONTAINER_COMMENTS, { containerId })
            const response = await axiosInstance.post(API_URL, payload)
            return response
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }


    const fetchContainerLogs = async (container_id: string | number) => {
        setLoading(true)
        try {
            const params = { container_id }
            const response = await axiosInstance.get(ApiRoutes.CONTAINER_LOGS, { params })
            const data = deserialize(ContainerLog, response.data["container_logs"]) as ContainerLog[]
            setContainerLogs(data)

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const downloadActivityItemsImages = async (userType: string, activityId: string | number, activity_item_id: string | number) => {
        setSubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_DOWNLOAD, { activityId, userType })
            console.log(API_URL);
            const response = await axiosInstance.post(API_URL, { activity_item_id }, {
                responseType: 'blob'
            })
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `Activity-Item-${activityId}-${activity_item_id}.zip`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const downloadAllActivityItemImages = async (userType: string, activityId: string | number) => {
        setSubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINERS_ACTIVITIES_SPECIFIC_DOWNLOAD_ALL, { activityId, userType })
            console.log(API_URL);
            const response = await axiosInstance.post(API_URL, {}, {
                responseType: 'blob'
            })
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `Activity-Item-${activityId}.zip`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    return {
        activity,
        loading,
        activities,
        container,
        submitting,
        activityItems,
        fetchActivites,
        activitiesMeta,
        containerLogs,
        exportActivites,
        activityLoading,
        createContainer,
        updateContainer,
        approveActivites,
        exportSubmitting,
        exportImagesSubmitting,
        createActivityItem,
        updateActivityItem,
        deleteActivityItem,
        containerActivities,
        updateActivityDate,
        fetchContainerLogs,
        containerComments,
        containerNavigation,
        fetchSingleContainer,
        exportBulkImageActivity,
        deleteContainerActivity,
        createContainerActivity,
        exportContainerActivity,
        updateContainerActivity,
        fetchContainerComments,
        createContainerComments,
        downloadContainerImages,
        containerActivitiesLoading,
        fetchContainerActivityItems,
        fetchSingleContainerActivites,
        fetchContainerActivityDetails,
        downloadActivityItemsImages,
        exportCustomerPendingActivity,
        downloadAllActivityItemImages,
        autoPopulatedActivityItemsForm,
    }
}

export default ContainerService